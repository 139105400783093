import { GatsbySeo } from 'gatsby-plugin-next-seo'

import ProdutosEco from '../components/ProdutosEco'

function DecathlonItau() {
  return (
    <>
      <GatsbySeo title="Produtosecoconcepcao | Decathlon" />
      <ProdutosEco />
    </>
  )
}

export default DecathlonItau
